<template>
  <v-container>
    <v-card flat class="mx-auto mt-5 mb-5 charcoal">
      <v-sheet class="charcoal" flat>
        <template>
          <BaseLoading v-if="!loaded" />
          <v-timeline v-if="activityList.length > 0" align-top :dense="isPhone">
            <v-timeline-item
              color="progressActive"
              fill-dot
              :left="index % 2 == 0"
              :right="index % 2 != 0"
              v-for="(item, index) in activityList"
              :key="item.id"
              :class="isPhone ? 'ml-n6' : ''"
            >
              <template v-slot:icon>
                <v-icon color="charcoal">
                  {{ item.icon }}
                </v-icon>
              </template>
              <template v-slot:opposite>
                <span class="caption silver--text">
                  {{ item.date }}
                </span>
              </template>
              <v-card
                class="charcoal pa-2 rounded-lg rounded-t-0 link mt-n2"
                :style="charcoalTile"
                rounded
                @click="selectActivity(item)"
              >
                <v-card-actions class="silver--text py-0">
                  <span title="When">
                    {{ item.date }}
                  </span>
                  <v-spacer />
                  <v-icon color="silver">mdi-clock</v-icon>
                  {{ item.duration }}
                </v-card-actions>
                <v-card-actions :class="textSizeXSmall + ' py-0'">
                  <span title="Category" :class="textSize + ' paper--text'">
                    {{ item.type }}, {{ item.category }}
                  </span>
                  <v-spacer />
                </v-card-actions>

                <v-card-actions
                  :class="textSizeXSmall + ' pr-0 pl-2 py-0 silver--text'"
                >
                  {{ item.text }} {{ item.details ? item.details : '' }}
                </v-card-actions>
                <MyZoneDetails :item="item"/>
                <v-card-actions
                  class="pb-0 px-0"
                  v-if="item.calories_total > 0"
                >
                  <v-spacer />
                  <span
                    v-if="item.meps > 0"
                    title="MEPS"
                    :class="textSize + ' paper--text'"
                  >
                    {{ item.meps }} MEPS
                  </span>
                </v-card-actions>
                <v-card-actions
                  class="py-0 px-0"
                  v-if="item.calories_total > 0"
                >
                  <v-spacer />
                  <span
                    title="Calories Burned"
                    :class="textSizeLarge + ' purpleAccent--text'"
                  >
                    {{ Math.ceil(item.calories_total) }} kCal
                  </span>
                </v-card-actions>
                <v-card-text
                  v-if="item.distance_imperial > 0"
                  :class="textSizeXSmall + ' pa-0 px-1'"
                >
                  <span title="Distance" class="paper--text">
                    {{ item.distance_imperial }} miles or
                    {{ item.distance_metric }} Km
                  </span>
                  <v-spacer />
                  <span title="Pace" class="silver--text">
                    {{ item.pace_imperial }} mins/mile or
                    {{ item.pace_metric }} mins/Km
                  </span>
                  <v-spacer />
                  <span title="Pace" class="silver--text">
                    {{ item.speed_imperial }} mph or
                    {{ item.speed_metric }} Km/h
                  </span>
                </v-card-text>
                <template v-if="item.calories_per_minute > 0">
                  <v-card-actions :class="textSizeXSmall + ' pl-2 pr-0 py-0'">
                    <span title="Category" class="success--text">
                      {{ item.duration }} minutes @{{
                        item.calories_per_minute
                      }}
                      kCal/minute
                    </span>
                    <v-spacer />
                  </v-card-actions>
                </template>
              </v-card>
            </v-timeline-item>
          </v-timeline>
          <v-card-text v-else :class="textSizeSmall + ' silver--text px-0'">
            You have no activities logged for today.
          </v-card-text>
        </template>
      </v-sheet>
    </v-card>
    <v-dialog
      v-model="showRowInfo"
      :fullscreen="isPhone"
      width="900"
      overlay-opacity="0.95"
    >
      <v-card class="d-flex flex-column noscroll charcoal" tile>
        <BaseCloseDialogHeader
          heading="Delete Activity?"
          @cancel="showRowInfo = false"
        />

        <v-card-text
          dense
          :class="textSize + ' progressActive--text px-2  py-1'"
        >
          {{ capitalize(selectedActivity.text) }}
        </v-card-text>
        <v-card-subtitle :class="textSizeXSmall + ' silver--text px-2 pt-2'">
          Entered on: {{ selectedActivity.when }}
        </v-card-subtitle>
        <v-sheet class="mx-2 px-2 pb-10" :style="charcoalTile">
          <v-card-actions :class="textSize + ' px-0 pt-0 progressActive--text'">
            <v-divider class="charcoal lighten-1" />
            <span class="mx-2">
              {{ selectedActivity.type }}
            </span>
            <v-divider class="charcoal lighten-1" />
          </v-card-actions>
          <v-card-actions class="silver--text py-0 px-0">
            Calories per Minute
            <v-spacer />
            <span class="progressActive--text">
              {{ selectedActivity.calories_per_minute }}
            </span>
          </v-card-actions>
          <v-card-actions class="silver--text py-0 px-0">
            Total Calories
            <v-spacer />
            <span class="progressActive--text">
              {{ selectedActivity.calories_total }}
            </span>
          </v-card-actions>
          <v-card-actions class="silver--text py-0 px-0">
            METS
            <v-spacer />
            <span class="progressActive--text">
              {{ selectedActivity.met }}
            </span>
          </v-card-actions>
          <v-card-actions class="silver--text py-0 px-0">
            Duration
            <v-spacer />
            <span class="progressActive--text">{{
              selectedActivity.duration
            }}</span>
          </v-card-actions>
          <v-card-actions
            class="silver--text py-0 px-0"
            v-if="selectedActivity.distance_metric > 0"
          >
            Distance
            <v-spacer />
            <span class="progressActive--text"
              >{{ selectedActivity.distance_imperial }} miles,
              {{ selectedActivity.distance_metric }} Km</span
            >
          </v-card-actions>
          <v-spacer />
        </v-sheet>
        <v-card-subtitle :class="textSizeXSmall + ' silver--text px-2 pt-2'">
          Review the information above. Click the
          <span class="progressActive--text">Delete</span> button to remove the
          activity from the log.
        </v-card-subtitle>
        <v-spacer />

        <v-card-actions class="px-0">
          <BaseActionButton
            plain
            :large="!isPhone"
            icon="mdi-close"
            label="Cancel"
            color="silver"
            @clickedThis="showRowInfo = false"
          />
          <v-spacer />
          <BaseActionButton
            plain
            :large="!isPhone"
            label="Delete"
            dark
            class="charcoal paper--text"
            icon="mdi-trash-can-outline"
            @clickedThis="deleteActivity"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const MyZoneDetails = () =>
  import(/* webpackPrefetch: true */ '@/components/blocks/MyZoneDetails.vue')
export default {
  components: { MyZoneDetails },
  name: 'ActivityLog',
  mixins: [util],
  props: {
    show: {
      type: Boolean
    },
    day: {
      type: String
    }
  },
  watch: {
    show: function(value) {
      if (value) this.loadPage()
      else {
        this.loaded = false
        this.activityList = []
      }
    }
  },
  data() {
    return {
      loaded: false,
      selectedActivity: {},
      showRowInfo: false,
      activityList: []
    }
  },
  beforeMount() {},

  computed: {
    ...appConfig
  },
  methods: {
    refreshPage() {
      this.loaded = false
      this.loadPage()
    },

    setResults(value) {
      this.activityList = value
    },
    selectActivity(value) {
      this.showRowInfo = true
      this.selectedActivity = value
    },
    deleteActivity() {
      return axios
        .delete(this.baseURL + '/activity/' + this.selectedActivity.id)
        .then(response => {
          if (response.status == 200) {
            this.selectedActivity = {}
            this.showRowInfo = false
            this.currentPage = 0
            this.loadPage()
            this.toast('Activity deleted succesfully.')
            this.$emit('deleted_activity')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadPage() {
      this.loaded = false
      return axios
        .get(this.baseURL + '/activities/day/' + this.day, {})
        .then(response => {
          if (response.status == 200) {
            this.activityList = response.data.data
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
